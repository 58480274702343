import React, { useRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { themeGet } from 'styled-system';
import { Box } from '@qga/roo-ui/components';
import { mediaQuery } from 'lib/styledSystem';
import { rem } from 'polished';
import SkipToContentButton from 'components/SkipToContentButton';

export const DropdownBase = styled(Box)`
  position: relative;

  right: auto;
  box-shadow: none;
  border: none;
  background: ${themeGet('colors.greys.porcelain')};
  z-index: ${themeGet('zIndices.modalContent')};

  ${mediaQuery.minWidth.sm} {
    position: absolute;
    margin-top: ${themeGet('space.2')};
    margin-bottom: ${themeGet('space.2')};
    padding-top: 0;
    ${(props) =>
      props.anchorX === 'right' &&
      css`
        right: 0;
      `}

    border: 1px solid ${themeGet('colors.greys.alto')};
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.12);
  }
`;

const Dropdown = ({ viewThreshold, children, skipToContent, cancelModal, ...rest }) => {
  const [position, setPosition] = useState({});
  const dropdownRef = useRef();

  useLayoutEffect(() => {
    if (!dropdownRef.current || !window) return {};
    const { top, height } = dropdownRef.current.getBoundingClientRect();
    const canDisplayBelow = height * (viewThreshold ?? 0.5) + top < window.innerHeight;
    // the viewThreshold represents how much of the dropdown must fit onscreen before it inverts and
    // is shown above the parent. By default, if less than 50% of the dropdown is visible it will invert

    setPosition(canDisplayBelow ? { top: '100%' } : { bottom: '100%' });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DropdownBase ref={dropdownRef} {...rest} {...(viewThreshold ? position : {})}>
      {skipToContent && (
        <SkipToContentButton as="a" href={skipToContent} onClick={cancelModal}>
          Skip to content
        </SkipToContentButton>
      )}
      {children}
    </DropdownBase>
  );
};

Dropdown.propTypes = {
  children: PropTypes.node,
  viewThreshold: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.number]),
  skipToContent: PropTypes.string,
  cancelModal: PropTypes.func,
};

Dropdown.defaultProps = {
  children: null,
  width: ['100%', rem('530px'), rem('750px')],
  skipToContent: undefined,
  cancelModal: () => {},
};

export default Dropdown;
