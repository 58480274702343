import React from 'react';
import PropTypes from 'prop-types';
import { themeGet } from 'styled-system';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { Flex, Button } from '@qga/roo-ui/components';
import includes from 'lodash/includes';
import { FOOTER, UPDATED_FOOTER, ANIMATION_DURATION } from './constants';
import TreatmentToggle from 'components/TreatmentToggle';

const PhoneOnlyFooter = styled(Flex)`
  padding: ${themeGet('space.2')};
  box-shadow: ${themeGet('shadows.footer')};
  background: ${themeGet('colors.white')};
  height: ${FOOTER ?? UPDATED_FOOTER}px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2000;
  opacity: 0;
  transition: opacity ${ANIMATION_DURATION}ms ease-in;

  ${({ transitionState }) =>
    includes(['entering', 'entered'], transitionState) &&
    css`
      opacity: 1;
    `};

  ${({ transitionState }) =>
    transitionState === 'exited' &&
    css`
      display: none;
    `};

  ${({ isMobileApp, transitionState }) =>
    includes(['entering', 'entered'], transitionState) &&
    isMobileApp &&
    css`
      bottom: 5%;
      left: 5%;
      right: 5%;
    `};
`;

PhoneOnlyFooter.displayName = 'PhoneOnlyFooter';

const UpdatedPhoneOnlyFooter = styled(PhoneOnlyFooter)`
  padding: ${themeGet('space.6')} ${themeGet('space.8')};
  height: ${UPDATED_FOOTER}px;
`;

const Footer = ({ buttonText, isMobileApp = false, submitModal, transitionState }) => (
  <>
    <TreatmentToggle split="jh_header_nav" treatment="off">
      <PhoneOnlyFooter transitionState={transitionState} isMobileApp={isMobileApp}>
        <Button variant="primary" block onClick={submitModal} data-testid="apply-button">
          {buttonText}
        </Button>
      </PhoneOnlyFooter>
    </TreatmentToggle>
    <TreatmentToggle split="jh_header_nav" treatment="on">
      <UpdatedPhoneOnlyFooter transitionState={transitionState} isMobileApp={isMobileApp}>
        <Button
          variant="secondary"
          block
          onClick={submitModal}
          data-testid="apply-button"
          py="2"
          style={{ fontSize: '.875rem', lineHeight: '1.25rem' }}
        >
          {buttonText}
        </Button>
      </UpdatedPhoneOnlyFooter>
    </TreatmentToggle>
  </>
);

Footer.propTypes = {
  buttonText: PropTypes.string,
  isMobileApp: PropTypes.bool,
  submitModal: PropTypes.func.isRequired,
  transitionState: PropTypes.string.isRequired,
};

Footer.defaultProps = {
  buttonText: 'Apply',
};

export default Footer;
